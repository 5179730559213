import React from 'react'
import { Cross } from '../Icons/Cross'
import { Help } from '../Icons/Help'

import {
    StyledModalWrapper,
    StyledTooltipIcon,
    StyledTooltipWrapper,
    StyledClosingButton,
    StyledTooltipCard,
    StyledTooltipStack,
} from './Tooltip.styled'
import { Backdrop } from '../Backdrop'
import FocusTrap from 'focus-trap-react'
import { useGameContext } from '@the-game/web-common'

import theme from '../theme'
import getPrimarySportColor from '../utils/getPrimarySportColor'

function ClosingButton({
    onClick,
}: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    const { sport } = useGameContext()
    const sportThemeColor = getPrimarySportColor(sport, theme)

    return (
        <StyledClosingButton type="button" onClick={onClick} aria-label="Close">
            <Cross fill={sportThemeColor} size="xs" />
        </StyledClosingButton>
    )
}

export function Tooltip({
    children,
    id,
    header,
    variant = 'right',
    large,
}: React.PropsWithChildren<{
    id: string
    header: React.ReactNode
    variant?: 'left' | 'right'
    large?: boolean
}>) {
    const [show, setShow] = React.useState(false)
    const toolTipElement = document.querySelector(`#${id}`) as HTMLElement

    const { sport } = useGameContext()
    const sportThemeColor = getPrimarySportColor(sport, theme)

    const handleClick = () => {
        setShow(!show)
    }

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault()
        setShow(false)
    }

    React.useEffect(() => {
        function keydownHandler({ key }: KeyboardEvent) {
            if (key === 'Escape') {
                setShow(false)
            }
        }

        if (show) {
            document.addEventListener('keydown', keydownHandler)

            if (toolTipElement) {
                toolTipElement.focus()
            }
        }

        return function tooltipEffectCleanup() {
            document.removeEventListener('keydown', keydownHandler)
        }
    }, [show, toolTipElement])

    return (
        <StyledTooltipWrapper>
            {show && <Backdrop onClick={handleClose} />}
            <StyledTooltipIcon
                large={large}
                type="button"
                onClick={handleClick}
                aria-label="More Information"
            >
                <Help fill={sportThemeColor} />
            </StyledTooltipIcon>

            <StyledModalWrapper id={id} role="dialog" tabIndex={-1}>
                {show && (
                    <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                        <div>
                            <StyledTooltipCard backgroundColor="white">
                                <StyledTooltipStack
                                    variant={variant}
                                    direction="row"
                                    gap="sm"
                                >
                                    {variant === 'left' && (
                                        <ClosingButton onClick={handleClose} />
                                    )}
                                    <div>{header}</div>
                                    {variant === 'right' && (
                                        <ClosingButton onClick={handleClose} />
                                    )}
                                </StyledTooltipStack>
                                <div>{children}</div>
                            </StyledTooltipCard>
                        </div>
                    </FocusTrap>
                )}
            </StyledModalWrapper>
        </StyledTooltipWrapper>
    )
}
